import { CssBaseline } from "@mui/material";
import { FullPageLoader } from "components/ui/FullPageLoader";
import { AppProviders } from "context/AppProviders";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./language/i18n";
import reportWebVitals from "./reportWebVitals";

export interface AppConfig {
  AUTH0_DOMAIN: string;
  AUTH0_AUDIENCE: string;
  AUTH0_CLIENTID: string;
  API_URL: string;
  ENVIRONMENT_NAME: string;
  GIPHY_API_KEY: string;
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
  FIREBASE_VAPID_KEY: string;
  PREAUTH_CLIENT_ID: string;
}

declare global {
  interface Window {
    APP_CONFIG: AppConfig;
  }
}

ReactDOM.render(
  <Suspense fallback={<FullPageLoader />}>
    <AppProviders>
      <StrictMode>
        <CssBaseline />
        <App />
      </StrictMode>
    </AppProviders>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
