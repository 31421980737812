import { useAuth } from "context/auth-context";
import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";

/**
 * Checks permissions to write for specific admin user
 */
export const usePermissions = () => {
  const { identity } = useAuth();
  const { activeTenant } = useTenant();
  const effectivePermissions = useMemo(() => {
    if (!identity) {
      return [];
    }

    const tenantPermissions =
      (activeTenant &&
        identity.tenantInfo &&
        identity.tenantInfo[activeTenant] &&
        identity.tenantInfo[activeTenant].permissions) ||
      [];

    return Array.from(new Set([...identity.permissions, ...tenantPermissions]));
  }, [identity, activeTenant]);

  const canAddPostsAsAdmin = effectivePermissions.includes("post:write-admin");
  const canPostAsOrganisation = effectivePermissions.includes("post:write-organisation");
  const canCreateCourses = effectivePermissions.includes("course:write");
  const canReadTags = effectivePermissions.includes("tag:read");
  const canReadNotifications = effectivePermissions.includes("notification:read");
  const canCreateReels = effectivePermissions.includes("reel:write");

  return {
    canAddPostsAsAdmin,
    canPostAsOrganisation,
    canCreateCourses,
    canReadTags,
    canReadNotifications,
    canCreateReels
  };
};
