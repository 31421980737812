import { Box, Dialog, DialogContent, DialogTitle, Slide, Typography, useMediaQuery } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { theme } from "@talkouttech/portal-shared";
import { BackIconWrapper, CloseIconWrapper } from "components/Modal/Modal.styles";
import React, { ReactNode, useRef } from "react";
import { HiX } from "react-icons/hi";
import { MdArrowBackIosNew } from "react-icons/md";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalProps {
  open: boolean;
  title?: string;
  closeModal: () => void;
  onBack?: () => void;
  modalSize?: "sm" | "xs" | "md" | "lg" | "xl";
  children: ReactNode;
  padding?: number | string;
  closeButton?: boolean;
  showHeader?: boolean;
}

const Modal = ({
  open,
  title,
  closeModal,
  onBack,
  modalSize,
  children,
  padding = 0,
  closeButton = true,
  showHeader = true
}: ModalProps) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const target = useRef<any>(null);

  return (
    <Dialog
      open={open}
      maxWidth={modalSize}
      onClose={closeModal}
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Transition}
    >
      {showHeader && (
        <DialogTitle sx={{ borderBottom: theme.borders.standard, padding: "24px 22px" }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            {onBack && (
              <BackIconWrapper aria-label="back" onClick={onBack}>
                <MdArrowBackIosNew size={16} color={theme.colors.black} />
              </BackIconWrapper>
            )}
            {title && <Typography variant="h3">{title}</Typography>}
            {closeButton && (
              <CloseIconWrapper aria-label="close" onClick={closeModal}>
                <HiX size={16} color={theme.colors.black} />
              </CloseIconWrapper>
            )}
          </Box>
        </DialogTitle>
      )}
      <DialogContent sx={{ p: padding }} ref={target}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
export { Modal };
