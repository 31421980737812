export interface NotificationData {
  id: string;
  organisationUserId: string;
  key?: string;
  message: string;
  status: NotificationStatus;
  linkType: NotificationLinkType;
  metadata: NotificationMetaData;
  subtype?: string;
  linkId: string;
  type: NotificationType;
  sendPush?: boolean;
  title: string;
  updatedOn?: string;
  _createdOn: string;
  feature: NotificationFeatureType;
}

export enum NotificationStatus {
  Read = "read",
  Viewed = "viewed",
  Unread = "unread"
}

export enum NotificationType {
  Post = "post",
  MandatoryPost = "mandatoryPost",
  PostMentioned = "postMentionAdded",
  PostMentionedAdded = "postCommentMentionAdded",
  PostAudience = "postAudience",
  FollowersAudience = "FollowersAudience",
  PostComment = "postComment",
  GroupInvite = "groupInvite",
  PostShared = "postShared",
  KudosPost = "kudosPost",
  PostCommentReaction = "postCommentReaction",
  PostReaction = "postReaction",
  BadgeEarned = "badgeEarned",
  Chat = "chat",
  Course = "courseAssigned",
  Answer = "answer",
  Reminders = "reminders",
  CourseReAssigned = "courseReAssigned",
  CourseInstanceApprovalRequest = "courseInstanceApprovalRequest"
}

export enum NotificationLinkType {
  None = "none",
  Post = "post",
  MandatoryPost = "mandatoryPost",
  PostComment = "postComment",
  Profile = "profile",
  Group = "groupInvite",
  Department = "department",
  Organisation = "organisation",
  Chat = "chat",
  Badge = "badgeEarned",
  Course = "course",
  Reel = "reelRemainder",
  Url = "url"
}

export enum NotificationFeatureType {
  Social = "social",
  Course = "course",
  Survey = "survey"
}

export declare type NotificationMetaData =
  | BaseMetaData
  | PostMetaData
  | MandatoryPostMetaData
  | PostReactionMetaData
  | PostCommentMetaData
  | PostCommentReactionMetaData
  | PostCommentReactionMetaData
  | ChatMetaData
  | PostCommentMentionedMetaData
  | PostMentionedMetaData
  | FollowerAddedMetaData
  | KudosPostMetaData
  | GroupInvitationMetaData
  | PostAudienceMetaData
  | FollowersPostAudienceMetaData
  | EarnedBadgeMetaData
  | ReelReminderMetaData;

export interface BaseMetaData {
  avatarUrl?: string;
  postType?: string;
  displayName?: string;
}

export interface PostMetaData extends BaseMetaData {
  postId?: string;
  displayName?: string;
  question?: string;
}

export interface CourseInstanceApprovalRequestMetaData extends BaseMetaData {
  courseName?: string;
  userDisplayName?: string;
}

export interface MandatoryPostMetaData extends BaseMetaData {
  audienceName?: string;
  postedByName?: string;
  postId?: string;
}

export interface CourseAssignemtnsMetaData extends BaseMetaData {
  courseName?: string;
}

export interface PostReactionMetaData extends BaseMetaData {
  displayName?: string;
  otherUserCount?: number;
  postCommentId?: string;
  reactionKey?: string;
  postId?: string;
}

export interface PostAudienceMetaData extends BaseMetaData {
  displayName?: string;
  audienceName?: string;
  postedByName?: string;
  postId?: string;
}

export interface PostSharedMetaData extends BaseMetaData {
  otherUserCount?: number;
  sharedPostId?: string;
  postId?: string;
  displayName?: string;
}

export interface PostCommentMetaData extends BaseMetaData {
  postedByName?: string;
  postCommentId?: string;
  postId?: string;
  postType?: string;
}

export interface PostCommentReactionMetaData extends BaseMetaData {
  otherUserCount?: number;
  postCommentId?: string;
  reactionKey?: string;
  postId?: string;
  displayName?: string;
}

export interface ChatMetaData extends BaseMetaData {}

export interface PostCommentMentionedMetaData extends BaseMetaData {
  postCommentId?: string;
  postId?: string;
}

export interface PostMentionedMetaData extends BaseMetaData {
  postId?: string;
  displayName?: string;
}

export interface FollowerAddedMetaData extends BaseMetaData {
  postId?: string;
  displayName?: string;
}

export interface KudosPostMetaData extends BaseMetaData {
  kudosId?: string;
  kudosImage?: string;
  kudosName?: string;
  postedByName?: string;
  postId?: string;
}

export interface GroupInvitationMetaData extends BaseMetaData {
  invitedByAvatar?: string;
  invitedByName?: string;
  displayName?: string;
  postId?: string;
}

export interface FollowersPostAudienceMetaData extends BaseMetaData {
  postedByName?: string;
  audienceName?: string;
  postId?: string;
}

export interface ReelReminderMetaData extends BaseMetaData {
  reelPostId?: string;
  reelTitle?: string;
  videoThumbnailUrl?: string;
}

export interface KnowledgeRequestMetaData extends BaseMetaData {
  postCommentId: string;
  postId: string;
  postType: "";
  postedByName: string;
  reactionKey?: string;
  otherUserCount?: number;
  replyToId?: string;
}

export interface EarnedBadgeMetaData {
  badgeDescription?: string;
  badgeId?: string;
  badgeInstanceId?: string;
  badgeLevel?: string;
  badgeName?: string;
  imageUrl?: string;
}
