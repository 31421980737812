import { PostAudience, PostAudienceType } from "@talkouttech/portal-shared";
import { ReactionsTuple } from "@talkouttech/portal-shared/dist/components/widgets/PostPreview/components/PostInteractionsSection";
import { ConfiguredReaction } from "domain/features";
import { CommentReaction, PostReaction } from "domain/post";

export const getMappedPostReactions = (
  postReactions: {
    [key: string]: number;
  },
  reactions: ConfiguredReaction[] | undefined
) => {
  const postReactionsArray = Object.entries(postReactions);
  let reactionsArray = postReactionsArray.filter(item => item[1] > 0).map(item => item[0]);

  for (let i = 0; i < 5; i++) {
    if (reactionsArray[i] === undefined) {
      reactionsArray[i] = "";
    } else if (reactions !== undefined) {
      const reaction = reactions.find(item => item.key === reactionsArray[i]) ?? { key: "like", emote: "👍" };
      reactionsArray[i] = reaction.emote;
    }
  }

  return reactionsArray;
};

export const mapReactionToKey = (key: string | undefined, reactions: ConfiguredReaction[] | undefined) => {
  if (!key || !reactions) return null;
  return {
    key,
    emote: reactions?.filter(item => item.key === key)[0]?.emote || ""
  };
};

export const getReactions = (data: Array<PostReaction | CommentReaction>, reaction: string) =>
  data.filter(({ key }) => key === reaction);

export const displayReactionsEmoji = (totalReactions: number, reactions: ReactionsTuple) =>
  totalReactions > 0 ? `${reactions.map(reaction => `${reaction}`).join("")} ${totalReactions}` : "";

export const getAudienceToSend = (audience: PostAudience) => {
  const { type } = audience;
  if (type === PostAudienceType.Followers) {
    return {
      type: PostAudienceType.Followers
    };
  }
  if (type === PostAudienceType.Department) {
    return {
      type: PostAudienceType.Department,
      departmentId: audience.departmentId
    };
  }
  if (type === PostAudienceType.Group) {
    return {
      type: PostAudienceType.Group,
      groupId: audience.groupId
    };
  }
  if (type === PostAudienceType.Organisation) {
    return {
      type: PostAudienceType.Organisation,
      organisationId: audience.organisationId
    };
  }
  return {
    type: PostAudienceType.Composite,
    items: audience.items
  };
};
