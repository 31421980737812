import { useGetCurrentUserProfile } from "application/users";
import { useClient } from "context/auth-context";
import { NotificationData } from "domain/notifications";
import { usePermissions } from "hooks/usePermissions";
import { useTenant } from "hooks/useTenant";
import { useMutation, useQuery, useQueryClient } from "react-query";

const notificationsFetchInterval = 1000 * 30;

export const useGetCurrentUserNotifications = () => {
  const client = useClient<NotificationData[]>();
  const {
    currentProfile: { id: userId }
  } = useGetCurrentUserProfile();
  const { activeTenant } = useTenant();
  const { canReadNotifications } = usePermissions();

  const { data } = useQuery<NotificationData[], Error>({
    queryKey: [`user-notifications`, activeTenant, userId],
    queryFn: () => client(`tenants/${activeTenant}/profiles/${userId}/notifications`),
    enabled: !!userId && canReadNotifications,
    refetchInterval: notificationsFetchInterval
  });

  return { data: data ?? [] };
};

export const useMarkAllNotificationsAsViewed = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { activeTenant } = useTenant();
  const {
    currentProfile: { id: userId }
  } = useGetCurrentUserProfile();

  const mutation = useMutation<unknown, Error>(
    () =>
      client(`tenants/${activeTenant}/profiles/${userId}/notifications`, {
        method: "PATCH",
        data: {
          status: "viewed"
        }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`user-notifications`, activeTenant, userId]);
      }
    }
  );

  return mutation;
};

export const useMarkNotificationAsRead = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { activeTenant } = useTenant();
  const {
    currentProfile: { id: userId }
  } = useGetCurrentUserProfile();

  const mutation = useMutation<unknown, Error, { id: string }>(
    updates =>
      client(`tenants/${activeTenant}/profiles/${userId}/notifications/${updates.id}`, {
        method: "PATCH",
        data: {
          status: "read"
        }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`user-notifications`, activeTenant, userId]);
      }
    }
  );

  return mutation;
};
