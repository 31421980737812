import { useAuth, useClient } from "context/auth-context";
import { FeatureConfiguration } from "domain/features";
import { useTenant } from "hooks/useTenant";
import { useQuery } from "react-query";
import { client as pureClient } from "utils/api-client";

export const defaultConfiguration: FeatureConfiguration = {
  activeServices: {
    paths: false,
    worlds: false
  },
  canViewFollowers: false,
  reactions: [
    { key: "like", emote: "👍" },
    { key: "love", emote: "❤️" },
    { key: "laugh", emote: "😂" },
    { key: "shock", emote: "😮" },
    { key: "celebrate", emote: "👏" },
    { key: "thinking", emote: "🤔" }
  ],
  interests: [],
  kudosCategories: [],
  enableMultipleOrganisations: false,
  appConfiguration: {
    isSocialEnabled: true,
    isKudosEnabled: true,
    isChatEnabled: true,
    isSurveysEnabled: true,
    isCoursesEnabled: true,
    isCompanyGoalsEnabled: true,
    isCompanyValuesEnabled: true,
    isContentZoneEnabled: true,
    isMHFAEnabled: true,
    isGroupsEnabled: true,
    isUserPostAndCommentEnabled: true,
    isKnowledgeReelsEnabled: true,
    isKnowledgeRequestsEnabled: true,
    maxNumberOfMediaInComments: 0,
    maxPostLength: 0,
    postViewDurationMs: 500,
    showExternalContentInSemanticSearchResults: false,
    chatConnectionTimeout: 3000
  },
  profileVisibility: {
    hideOtherUsersInApp: false,
    replacementDisplayName: "User"
  },
  allowPostToAncestorAndDescendantOrganisations: false,
  allowUserPostToDepartment: false,
  includeStructureOptionsInGroupAudienceList: true,
  hashTagRegex: { pattern: "", flags: "" },
  urlRegex: { pattern: "", flags: "" },
  flags: {
    isSocialEnabled: false,
    isKudosEnabled: false,
    isContentZoneEnabled: false,
    isBadgesEnabled: false,
    isChatEnabled: false,
    isSurveysEnabled: false,
    isCoursesEnabled: false,
    isCompanyGoalsEnabled: false,
    isCompanyValuesEnabled: false,
    isMHFAEnabled: false,
    isGroupsEnabled: false,
    isUserPostAndCommentEnabled: false,
    isKnowledgeReelsEnabled: true,
    isKnowledgeRequestsEnabled: true,
    isRemindersEnabled: false
  },
  authConfig: {
    allowedPhoneCountryCodes: [],
    authFlow: "webFlow"
  },
  emailRegex: {
    flags: "",
    pattern: ""
  },
  apiVersion: 1,
  surveyMoodQuestionOptions: [],
  happinessPulseEmoji: []
};

function usePublicFeatures() {
  const { data, isLoading } = useQuery<FeatureConfiguration, Error>({
    queryKey: [`auth-configs`],
    queryFn: () =>
      pureClient<FeatureConfiguration>(`features/public`)
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return defaultConfiguration;
    }
  });

  return { features: data ?? defaultConfiguration, isLoading };
}

function useTenantFeaturesWithoutAppClient(token?: string) {
  const { activeTenant } = useTenant();
  const { features: publicFeatures } = usePublicFeatures();
  const { data, isLoading } = useQuery<FeatureConfiguration, Error>({
    enabled: !!activeTenant && !!token,
    queryKey: [`auth-configs-pure`, activeTenant],
    queryFn: () =>
      pureClient<FeatureConfiguration>(`tenants/${activeTenant}/features`, { token })
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return publicFeatures;
    }
  });

  return { features: data ?? publicFeatures, isLoading };
}

const useApiVersion = () => {
  const { identity } = useAuth();
  const { features } = useTenantFeaturesWithoutAppClient(identity?.token);
  return { apiVersion: features.apiVersion };
};

function useFeaturesQuery() {
  const client = useClient<FeatureConfiguration>();
  const { activeTenant } = useTenant();

  const { data, isLoading } = useQuery<FeatureConfiguration, Error>({
    enabled: !!activeTenant,
    queryKey: `tenant-${activeTenant}-features`,
    queryFn: () =>
      client(`tenants/${activeTenant}/features`)
        .then(data => data)
        .catch(error => error),
    onError: _ => {
      return defaultConfiguration;
    }
  });

  const postViewDurationMs = data?.appConfiguration?.postViewDurationMs ?? 500;

  const featuresConfig = data
    ? {
        ...data,
        appConfiguration: {
          ...data.appConfiguration,
          postViewDurationMs,
          isKnowledgeRequestsEnabled:
            (data?.flags?.isKnowledgeRequestsEnabled && data.appConfiguration.isKnowledgeRequestsEnabled) || false
        },
        flags: {
          isSocialEnabled: (data?.flags?.isSocialEnabled && data.appConfiguration.isSocialEnabled) || false,
          isKudosEnabled: (data?.flags?.isKudosEnabled && data.appConfiguration.isKudosEnabled) || false,
          isContentZoneEnabled:
            (data?.flags?.isContentZoneEnabled && data.appConfiguration.isContentZoneEnabled) || false,
          isBadgesEnabled: data?.flags?.isBadgesEnabled || false,
          isChatEnabled: (data?.flags?.isChatEnabled && data.appConfiguration.isChatEnabled) || false,
          isSurveysEnabled: (data?.flags?.isSurveysEnabled && data.appConfiguration.isSurveysEnabled) || false,
          isCoursesEnabled: (data?.flags?.isCoursesEnabled && data.appConfiguration.isCoursesEnabled) || false,
          isCompanyGoalsEnabled:
            (data?.flags?.isCompanyGoalsEnabled && data.appConfiguration.isCompanyGoalsEnabled) || false,
          isCompanyValuesEnabled:
            (data?.flags?.isCompanyValuesEnabled && data.appConfiguration.isCompanyValuesEnabled) || false,
          isMHFAEnabled: (data?.flags?.isMHFAEnabled && data.appConfiguration.isMHFAEnabled) || false,
          isGroupsEnabled: (data?.flags?.isGroupsEnabled && data.appConfiguration.isGroupsEnabled) || false,
          isUserPostAndCommentEnabled:
            (data?.flags?.isUserPostAndCommentEnabled && data.appConfiguration.isUserPostAndCommentEnabled) || false,
          isKnowledgeReelsEnabled:
            (data?.flags?.isKnowledgeReelsEnabled && data.appConfiguration.isKnowledgeReelsEnabled) || false,
          isKnowledgeRequestsEnabled:
            (data?.flags?.isKnowledgeRequestsEnabled && data.appConfiguration.isKnowledgeRequestsEnabled) || false,
          isFollowersEnabled: data?.flags?.isFollowersEnabled || false,
          isRemindersEnabled: data?.flags?.isRemindersEnabled || false,
          isCourseDiscussionEnabled: data?.flags?.isCourseDiscussionEnabled || false
        }
      }
    : defaultConfiguration;

  return { featuresConfig: featuresConfig, isLoading };
}

export { useApiVersion, useFeaturesQuery, usePublicFeatures, useTenantFeaturesWithoutAppClient };
