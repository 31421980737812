import { v4 as uuidv4 } from "uuid";

const UUID_STORAGE_KEY = "deviceId";

export const useBrowserId = () => {
  const idFromLocalStorage = localStorage.getItem(UUID_STORAGE_KEY);
  if (idFromLocalStorage) {
    return idFromLocalStorage;
  } else {
    const uuid = uuidv4();
    localStorage.setItem(UUID_STORAGE_KEY, uuid);
    return uuid;
  }
};
