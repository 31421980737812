import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";

export const CircleIconWrapper = styled(Box)<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const FakeButton = styled(Box)<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: ${theme.borderRadius.small}px;
  background-color: ${({ color }) => color};
  color: #ffffff;
  font-weight: bold;
`;

export const ReminderCardWrapper = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 20px 40px rgba(184, 184, 184, 0.32);
  border-radius: ${theme.borderRadius.small}px;
`;
