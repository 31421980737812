import { Backdrop, Box, LinearProgress } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";
import { ReactComponent as ReelyzeSygnet } from "assets/ReelyzeSygnet.svg";
import { LogoWrapper } from "components/ui/FullPageLoader/FullPageLoader.styles";

const FullPageLoader = () => (
  <Backdrop
    style={{ zIndex: theme.zIndex.drawer + 1, color: theme.colors.white, backgroundColor: theme.colors.white }}
    open
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <LogoWrapper>
        <ReelyzeSygnet width={80} height={80} />
      </LogoWrapper>
      <Box sx={{ width: "15vw", margin: "0 auto 20px", color: theme.colors.primary }}>
        <LinearProgress color="inherit" sx={{ marginTop: "10px" }} />
      </Box>
    </Box>
  </Backdrop>
);

export { FullPageLoader };
