import { Loader } from "@talkouttech/portal-shared";
import { useRedirectAfterAuthUrl } from "application/auth/auth";
import { onMessageListener } from "application/firebaseinit";
import { useMarkNotificationAsRead } from "application/notifications";
import { useTenantDetails } from "application/tenant";
import { RemidersModal } from "components/Reminders/Reminders";
import { useAuth } from "context/auth-context";
import { useToast } from "context/toast-context";
import { NotificationLinkType } from "domain/notifications";
import { useNavigationHelper } from "hooks/useNavigationHelper";
import { FC, lazy, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { PayloadNotificationData, showNotification } from "utils/notification-utils";

const AuthenticatedApp = lazy(() => import("./presentation/AuthenticatedApp"));
const InviteLoader = lazy(() => import("./presentation/AuthFlow"));
const InviteAppWrapper = lazy(() => import("./InviteAppWrapper"));

const App = () => {
  const [, setValue] = useSessionStorage("wasLoadedFromLink", false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectValue = queryParams.get("src");

  useEffect(() => {
    if (redirectValue === "applink") {
      setValue(true);
    }
  }, [setValue, redirectValue]);

  return (
    <Switch>
      <Route path="/healthcheck" render={() => <p>OK</p>} />
      <Route path="/invite" render={() => <InviteAppWrapper />} />
      <Route path="/login" render={() => <InviteLoader />} />
      <Route
        render={() => (
          <PrivateContent>
            <AuthenticatedApp />
            <RemidersModal />
          </PrivateContent>
        )}
      />
    </Switch>
  );
};

export default App;

const PrivateContent: FC = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { isLoading: areTenantDetailsLoading } = useTenantDetails();
  const history = useHistory();
  const { t } = useTranslation();
  const { showSnackBar } = useToast();
  const { mutate: markAsRead } = useMarkNotificationAsRead();
  const { navigateToPostDetail, navigateToGroup } = useNavigationHelper();
  const [redirectUrl, setRedirectUrl] = useRedirectAfterAuthUrl();

  const handleNotificationsNavigation = useCallback(
    (type: string, id: string, postId?: string) => {
      switch (type) {
        case NotificationLinkType.Group:
          return () => {
            markAsRead({ id });
            if (postId) navigateToGroup(postId);
          };
        default:
          return () => {
            markAsRead({ id });
            if (postId) navigateToPostDetail(postId);
          };
      }
    },
    [markAsRead, navigateToGroup, navigateToPostDetail]
  );

  const listenForPush = useCallback(() => {
    onMessageListener()
      .then(payload => {
        const payloadObject = payload as Partial<{
          data: PayloadNotificationData;
          notification: { title: string; body: string };
        }>;
        showNotification(payloadObject, showSnackBar, t, handleNotificationsNavigation);
      })
      .catch(err => console.log("failed: ", err));
  }, [handleNotificationsNavigation, showSnackBar, t]);

  useEffect(() => {
    listenForPush();
    if (!isAuthenticated && !isLoading) {
      if (!redirectUrl && window.location.pathname !== "/") {
        let target = window.location.pathname;
        if (window.location.search) {
          target += `?${window.location.search}`;
        }
        setRedirectUrl(target);
      }
      history.push("/login");
    }
    return () => listenForPush();
  }, [isAuthenticated, history, isLoading, listenForPush, setRedirectUrl, redirectUrl]);

  if (isLoading) return <Loader />;
  if (areTenantDetailsLoading) return <Loader />;
  return isAuthenticated ? <>{children}</> : null;
};
