import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastProvider } from "./toast-context";
import { ReactQueryDevtools } from "react-query/devtools";

import { ThemeProvider } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "./auth-context";
import { FullPageLoader } from "components/ui/FullPageLoader";
import { ScrollToTop } from "components/ScrollToTop";

const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;

const queryConfig = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      staleTime: MILLISECONDS_IN_HOUR,
      cacheTime: MILLISECONDS_IN_HOUR
    }
  }
});

export const BasicProviders: FC = ({ children }) => (
  <React.Suspense fallback={<FullPageLoader />}>
    <QueryClientProvider client={queryConfig}>
      <Router>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <ToastProvider>{children}</ToastProvider>
        </ThemeProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.Suspense>
);

const AppProviders: FC = ({ children }) => (
  <Auth0Provider
    domain={window.APP_CONFIG.AUTH0_DOMAIN}
    clientId={window.APP_CONFIG.AUTH0_CLIENTID}
    redirectUri={`${window.location.origin}/home`}
    audience={window.APP_CONFIG.AUTH0_AUDIENCE}
    useRefreshTokens
    cacheLocation="localstorage"
    onRedirectCallback={appState => {
      if (appState?.returnTo) {
        window.location.href = appState!.returnTo;
      }
    }}
    scope={`openid offline_access profile email appenv:${window.APP_CONFIG.ENVIRONMENT_NAME}`}
  >
    <AuthProvider>
      <BasicProviders>{children}</BasicProviders>
    </AuthProvider>
  </Auth0Provider>
);

export { AppProviders };
