import { notificationsResultFactory } from "components/Notifications/NotificationsFactory";
import {
  NotificationData,
  NotificationFeatureType,
  NotificationLinkType,
  NotificationMetaData,
  NotificationStatus,
  NotificationType
} from "domain/notifications";
import { ReactNode } from "react";
import { TFunction } from "react-i18next";

export interface PayloadNotificationData extends Omit<Partial<NotificationData>, "metadata"> {
  metadata: string;
  notificationId: string;
}

export const showNotification = (
  payloadObject: Partial<{
    data: PayloadNotificationData;
    notification: {
      title: string;
      body: string;
    };
  }>,
  showSnackBar:
    | ((
        text: string,
        untranslatable?: string | undefined,
        severity?: "error" | "success" | "info" | "warning" | undefined,
        component?: ReactNode,
        keepAliveFor?: number | undefined
      ) => void)
    | undefined,
  t: TFunction<"translation", undefined>,
  handleNotificationsNavigation: (type: string, id: string, postId?: string) => () => void
) => {
  const payloadData = payloadObject.data;

  if (!payloadData) {
    return;
  }

  const date = new Date().toISOString();

  const notification: NotificationData = {
    linkId: payloadData.linkId ?? "",
    id: payloadData.notificationId ?? "",
    organisationUserId: "",
    message: "",
    status: NotificationStatus.Read,
    linkType: payloadData.linkType ?? NotificationLinkType.None,
    metadata: JSON.parse(payloadData.metadata) as NotificationMetaData,
    type: payloadData.type ?? NotificationType.Post,
    title: payloadObject.notification?.title ?? "",
    _createdOn: date,
    feature: NotificationFeatureType.Social,
    updatedOn: ""
  };

  if (notification.type === NotificationType.Chat) {
    showSnackBar && showSnackBar("", undefined);
  } else {
    showSnackBar &&
      showSnackBar(
        "Push",
        undefined,
        undefined,
        notificationsResultFactory(t, handleNotificationsNavigation)[notification.type](notification),
        5000
      );
  }
};
