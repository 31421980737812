import { useAuth, useClient } from "context/auth-context";
import { useToast } from "context/toast-context";
import { Tenant } from "domain/company";
import { Badge, FollowedUsers, FullUserBadge, PlatformUser, UserInterest, UserProfileSummary } from "domain/user";
import { useTenant } from "hooks/useTenant";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { client } from "utils/api-client";

export const defaultProfileState: UserProfileSummary = {
  interests: [""],
  aboutMe: "",
  aboutRole: "",
  id: "",
  platformUserId: "",
  displayName: "",
  firstName: "",
  lastName: "",
  avatarUrl: "",
  departmentId: "",
  departmentName: "",
  jobRole: "",
  organisationId: "",
  organisationName: "",
  isSuspended: false,
  stats: {
    followers: 0,
    following: 0,
    posts: 0,
    kudos: 0,
    reactions: 0
  },
  services: []
};

export const useGetCurrentUserProfile = () => {
  const { identity } = useAuth();
  const { activeTenant } = useTenant();

  const isStaff = identity?.permissions?.includes("tenant:admin-read");

  const id =
    (activeTenant &&
      identity &&
      identity.tenantInfo[activeTenant] &&
      identity.tenantInfo[activeTenant].organisationUserId) ??
    (isStaff ? "00000000-0000-0000-0000-000000000000" : undefined);

  const token = identity?.token;

  const { data, isLoading } = useQuery<UserProfileSummary, Error>({
    enabled: !!id && !!token && !!activeTenant,
    queryKey: [`user-profile`, activeTenant],
    queryFn: () =>
      client<UserProfileSummary>(`tenants/${activeTenant}/profiles/${id}/full`, { token }).then(data => data)
  });

  return { currentProfile: data ?? defaultProfileState, isLoading };
};

export const useUserProfileSummary = (userId: string) => {
  const client = useClient<UserProfileSummary>();
  const { activeTenant } = useTenant();

  const { data, isLoading, isSuccess } = useQuery<UserProfileSummary, Error>({
    queryKey: [`current-user-profile`, activeTenant, userId],
    enabled: !!userId,
    queryFn: () => client(`tenants/${activeTenant}/profiles/${userId}/full`).then(data => data)
  });

  return { data: data ?? null, isLoading, isSuccess };
};

export const useFollowUser = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { activeTenant } = useTenant();
  const { showSnackBar } = useToast();
  const {
    currentProfile: { id: currentUserId }
  } = useGetCurrentUserProfile();

  const mutation = useMutation<any, Error, { followingId: string }>(
    updates => {
      return client(`tenants/${activeTenant}/profiles/${currentUserId}/following`, {
        method: "POST",
        data: { followingId: updates.followingId }
      });
    },
    {
      onMutate: async followObject => {
        await queryClient.cancelQueries(["user-profile-following", currentUserId, activeTenant]);

        const previousFollowed = queryClient.getQueryData(["user-profile-following", currentUserId, activeTenant]);

        queryClient.setQueryData(["user-profile-following", currentUserId, activeTenant], oldData => [
          ...(oldData as FollowedUsers[]),
          { id: followObject.followingId }
        ]);

        return { previousFollowed };
      },
      onError: (context: any) => {
        queryClient.setQueryData(["user-profile-following", currentUserId, activeTenant], context.previousFollowed);
        showSnackBar && showSnackBar(`failFollow`, undefined, "error");
      },
      onSettled: async (_, variables) => {
        queryClient.invalidateQueries(["user-profile-following", currentUserId, activeTenant]);
      }
    }
  );

  return mutation;
};

export const useUnfollowUser = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { activeTenant } = useTenant();
  const { showSnackBar } = useToast();
  const {
    currentProfile: { id: currentUserId }
  } = useGetCurrentUserProfile();

  const mutation = useMutation<any, Error, { followerId: string }>(
    updates => {
      return client(`tenants/${activeTenant}/profiles/${currentUserId}/following/${updates.followerId}`, {
        method: "DELETE"
      });
    },
    {
      onMutate: async followObject => {
        await queryClient.cancelQueries(["user-profile-following", currentUserId, activeTenant]);

        const previousFollowed = queryClient.getQueryData(["user-profile-following", currentUserId, activeTenant]);

        const currectFollowed = (previousFollowed as FollowedUsers[]).filter(
          item => item.id !== followObject.followerId
        );

        queryClient.setQueryData(["user-profile-following", currentUserId, activeTenant], currectFollowed);

        return { previousFollowed };
      },

      onError: (context: any) => {
        queryClient.setQueryData(["user-profile-following", currentUserId, activeTenant], context.previousFollowed);
        showSnackBar && showSnackBar(`failUnfollow`, undefined, "error");
      },
      onSettled: async _ => {
        queryClient.invalidateQueries(["user-profile-following", currentUserId, activeTenant]);
      }
    }
  );

  return mutation;
};

export const useGetFollowedUsers = () => {
  const client = useClient<FollowedUsers[]>();
  const { activeTenant } = useTenant();
  const {
    currentProfile: { id: currentUserId }
  } = useGetCurrentUserProfile();

  const { data, isLoading } = useQuery<FollowedUsers[], Error>({
    queryKey: ["user-profile-following", currentUserId, activeTenant],
    queryFn: () => client(`tenants/${activeTenant}/profiles/${currentUserId}/following`).then(data => data),
    enabled: !!currentUserId
  });

  return { data: data ?? [], isLoading };
};

export type PhotoType = "avatar" | "coverimage";

export const useUserProfilePhotoUpload = (
  photoType: PhotoType,
  isOnboarding?: boolean,
  onSuccessCallback?: () => void
) => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();
  const { activeTenant } = useTenant();

  const mutation = useMutation<any, Error, { formData: FormData; organisationUserId: string }>(
    updates =>
      client(`tenants/${activeTenant}/profiles/${updates.organisationUserId}/${photoType}`, {
        method: "PUT",
        data: updates.formData,
        formData: true
      }),
    {
      onError: () => {
        if (!isOnboarding) showSnackBar && showSnackBar(`failedToUploadThePhoto`, undefined, "error");
      },
      onSuccess: (updates, variables) => {
        queryClient.invalidateQueries(["user-profile"]);
        queryClient.invalidateQueries(["current-user-profile"]);
        queryClient.invalidateQueries(["post-items"]);
        queryClient.invalidateQueries(["post-reactions"]);
        queryClient.invalidateQueries(["user-posts-items"]);
        queryClient.invalidateQueries(["post-comments"]);
        queryClient.invalidateQueries(["post-comments-replies"]);

        onSuccessCallback && onSuccessCallback();
        if (!isOnboarding) showSnackBar && showSnackBar(`photoUploadedSuccessfully`, undefined, "success");
      }
    }
  );

  return mutation;
};

type EditUserProfileParams = {
  userId: string;
  isOnboarding?: boolean;
  callback?: () => void;
};

export const useEditUserProfile = ({ userId, isOnboarding = false, callback }: EditUserProfileParams) => {
  const client = useClient();
  const { activeTenant } = useTenant();
  const { showSnackBar } = useToast();
  const {
    currentProfile: { id: currentUserId }
  } = useGetCurrentUserProfile();

  const queryClient = useQueryClient();

  const mutation = useMutation<any, Error, Partial<UserProfileSummary>>(
    updates =>
      client(`tenants/${activeTenant}/profiles/${userId}`, {
        method: "PATCH",
        data: updates
      }),
    {
      onError: () => {
        if (!isOnboarding) showSnackBar && showSnackBar(`failedToUpdateProfile`, undefined, "error");
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["user-profile", activeTenant]);
        queryClient.invalidateQueries(["current-user-profile", activeTenant, currentUserId]);
        if (!isOnboarding) showSnackBar && showSnackBar(`profileUpdatedSuccessfully`, undefined, "success");
        callback && callback();
      }
    }
  );

  return mutation;
};

export const useGetInterests = () => {
  const client = useClient<UserInterest[]>();
  const { activeTenant } = useTenant();
  const { data: interests, isLoading } = useQuery<UserInterest[], Error>({
    enabled: !!activeTenant,
    queryKey: [`user-interests`, activeTenant],
    queryFn: () => client(`tenants/${activeTenant}/interests`).then(data => data)
  });
  return { data: interests ?? [], isLoading };
};

export const useGetPlatformUser = (id: string) => {
  const client = useClient<PlatformUser>();

  const { data, isLoading } = useQuery<PlatformUser, Error>({
    enabled: !!id,
    queryKey: [`platform-user`, id],
    queryFn: () => client(`platformusers/${id}`).then(data => data)
  });

  return { data, isLoading };
};

export const useGetTenantUsers = () => {
  const client = useClient<UserProfileSummary[]>();
  const { activeTenant } = useTenant();
  const { data, isLoading } = useQuery<UserProfileSummary[], Error>({
    queryKey: [`tenant-users`, activeTenant],
    queryFn: () => client(`tenants/${activeTenant}/users`).then(data => data)
  });

  return { data: data ?? [], isLoading };
};

export const useGetTenants = () => {
  const client = useClient<Tenant[]>();

  const { data, isLoading } = useQuery<Tenant[], Error>({
    queryKey: [`tenants-list`],
    queryFn: () =>
      client("tenants")
        .then(data => data)
        .catch(error => error)
  });

  return { data: data ?? [], isLoading };
};

export const useCurrentTenant = () => {
  const { activeTenant } = useTenant();
  const { data, isLoading } = useGetTenants();

  return { currentTenant: data.find(tenant => tenant.id === activeTenant), isLoading };
};

export const useGetUserEarnedBadges = (userId: string) => {
  const client = useClient<FullUserBadge[]>();
  const { activeTenant } = useTenant();

  const { data, isLoading, error } = useQuery<FullUserBadge[], Error>({
    queryKey: ["user-badges", activeTenant, userId],
    queryFn: () =>
      client(`tenants/${activeTenant}/profiles/${userId}/badges`)
        .then(data => data)
        .catch(error => error)
  });

  return { data: data ?? [], isLoading, error };
};

export const useGetAvailableBadges = (userId: string) => {
  const client = useClient<Badge[]>();
  const { activeTenant } = useTenant();

  const { data, isLoading, error } = useQuery<Badge[], Error>({
    queryKey: ["available-user-badges", activeTenant, userId],
    queryFn: () =>
      client(`tenants/${activeTenant}/profiles/${userId}/badges/available`)
        .then(data => data)
        .catch(error => error)
  });

  return { data: data ?? [], isLoading, error };
};
