import styled from "@emotion/styled";
import { Avatar, Badge, Card } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";

export const NotificationCardWrapper = styled(Card)<{ read: string }>`
  width: 100%;
  display: flex;
  background-color: ${({ read }) => (read === "true" ? `${theme.colors.white}` : `${theme.colors.notificationCard}`)};
  padding: ${theme.spacing(3)};
  border: ${theme.borders.standard};
  border-radius: ${theme.spacing(1)};
  margin: ${theme.spacing(1)} 0px;
  cursor: pointer;

  &:first-of-type {
    margin: 0px;
  }

  &:last-of-type {
    margin: 0px;
  }
`;

export const SmallAvatarBadge = styled(Avatar)`
  width: 20px;
  height: 20px;
  border: ${theme.borders.notifications};
`;

export const NotificationBadge = styled(Badge)<{ background: string }>`
  & .MuiAvatar-colorDefault {
    background-color: ${({ background }) => background};
  }
`;

export const NotificationGroupAvatar = styled(Avatar)`
  width: 45px;
  height: 45px;
  margin-right: ${theme.spacing(1)};
`;
