import { Box, Typography } from "@mui/material";
import { UserAvatar, theme } from "@talkouttech/portal-shared";
import Sygnet from "assets/Sygnet.png";
import { NotificationCardWrapper } from "components/Notifications/NotificationsCard/NotificationCard.styles";
import React, { FC } from "react";
import TimeAgo from "react-timeago";
import { timeAgoPostNotificationsFormatter } from "utils/time-utils";

interface NotificationCardProps {
  read: boolean;
  onClick?: () => void;
  backgroundColor?: string;
  notificationBadge?: React.ReactNode;
  avatarUrl: string;
  notificationMessage: React.ReactNode;
  _createdOn?: string;
  updatedOn?: string;
  rightComponent?: React.ReactNode;
}

const NotificationCard: FC<NotificationCardProps> = ({
  read,
  onClick,
  backgroundColor = "default",
  notificationBadge,
  avatarUrl,
  notificationMessage,
  _createdOn,
  updatedOn,
  rightComponent
}) => {
  return (
    <NotificationCardWrapper read={read.toString()} onClick={onClick && onClick}>
      <UserAvatar
        backgroundColor={backgroundColor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        notificationBadge={notificationBadge}
        src={avatarUrl ? avatarUrl : Sygnet}
      />
      <Box display="flex" width="100%" justifyContent="space-between">
        <Box pl={2} display="flex" flexDirection="column">
          {notificationMessage}
          {_createdOn && (
            <Typography variant="h5" fontWeight={500} color={theme.colors.notStarted}>
              <TimeAgo live={false} date={updatedOn ?? _createdOn} formatter={timeAgoPostNotificationsFormatter} />
            </Typography>
          )}
        </Box>
        {rightComponent && rightComponent}
      </Box>
    </NotificationCardWrapper>
  );
};

export { NotificationCard };
