// Import the functions you need from the SDKs you need
import { useGetCurrentUserProfile } from "application/users";
import { useClient } from "context/auth-context";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { useBrowserId } from "hooks/useBrowserId";
import { useTenant } from "hooks/useTenant";
import { useMutation } from "react-query";

const firebaseConfig = {
  apiKey: window.APP_CONFIG.FIREBASE_API_KEY,
  authDomain: window.APP_CONFIG.AUTH0_DOMAIN,
  projectId: window.APP_CONFIG.FIREBASE_PROJECT_ID,
  storageBucket: window.APP_CONFIG.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.APP_CONFIG.FIREBASE_MESSAGING_SENDER_ID,
  appId: window.APP_CONFIG.FIREBASE_APP_ID,
  measurementId: window.APP_CONFIG.FIREBASE_MEASUREMENT_ID
};

const vapidKey = window.APP_CONFIG.FIREBASE_VAPID_KEY;

export const app = initializeApp(firebaseConfig);

const fmessaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging();
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export async function getFCMToken(tokenCallback: (token: string) => void) {
  const messaging = await fmessaging;
  if (!messaging) {
    return;
  }
  try {
    const token = await getToken(messaging, { vapidKey: vapidKey });

    tokenCallback(token);
  } catch (e) {
    console.log("getFCMToken error", e);
    return undefined;
  }
}

export const onMessageListener = async () => {
  const messaging = await fmessaging;
  if (!messaging) {
    return;
  }

  return new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
};

export const useSendFCMToken = () => {
  const {
    currentProfile: { id: organisationUserId }
  } = useGetCurrentUserProfile();
  const client = useClient();
  const { activeTenant } = useTenant();

  const deviceId = useBrowserId();
  const platform = "web";

  const mutation = useMutation<unknown, Error, string>(token =>
    client(`tenants/${activeTenant}/profiles/${organisationUserId}/push/tokens`, {
      data: { deviceId, platform, token }
    })
  );

  return mutation;
};
