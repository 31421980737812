import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";
import { useFeaturesQuery } from "application/features";
import { useReminders } from "application/reminders";
import { useRemindersFollowup, useRemindersModalStore } from "application/reminders/reminders-store";
import { useGetCurrentUserProfile } from "application/users";
import { ReactComponent as RemindersAnswers } from "assets/RemindersAnswers.svg";
import { ReactComponent as RemindersCoures } from "assets/RemindersCoures.svg";
import { ReactComponent as RemindersFeedback } from "assets/RemindersFeedback.svg";
import { ReactComponent as RemindersPosts } from "assets/RemindersPosts.svg";
import { ReactComponent as RemindersReels } from "assets/RemindersReels.svg";
import { ReactComponent as RemindersVerify } from "assets/RemindersVerify.svg";
import { Modal } from "components/Modal";
import { CircleIconWrapper, ReminderCardWrapper } from "components/Reminders/Reminders.styles";
import { Reminders } from "domain/reminders";
import { useNavigationHelper } from "hooks/useNavigationHelper";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface RemiderGridItem {
  type: ReminderType;
  count: number;
  reminders?: Reminders;
}

enum ReminderType {
  KnowledgeRequestsAnswers = "knowledgeRequestsAnswers",
  KnowledgeRequestVerification = "knowledgeRequestVerification",
  Reels = "reels",
  Courses = "courses",
  Posts = "posts",
  Surveys = "surveys"
}

function RemindersComponent() {
  const { t } = useTranslation();

  const { data: reminders, isLoading } = useReminders();

  const remindersStateToListOfCounts = useMemo(() => {
    const list: RemiderGridItem[] = [];

    list.push({
      type: ReminderType.KnowledgeRequestVerification,
      count: reminders?.krRequiredVerification?.length ?? 0
    });

    list.push({
      type: ReminderType.KnowledgeRequestsAnswers,
      count: reminders?.krRequiredAnswer?.length ?? 0
    });

    list.push({
      type: ReminderType.Reels,
      count: reminders?.reels?.length ?? 0
    });

    list.push({
      type: ReminderType.Courses,
      count: reminders?.courses?.length ?? 0
    });

    list.push({
      type: ReminderType.Surveys,
      count: reminders?.surveys?.length ?? 0
    });

    list.push({
      type: ReminderType.Posts,
      count: reminders?.posts?.length ?? 0
    });

    return list;
  }, [reminders]);

  return (
    <Box px={2} py={4} display="flex" flexDirection="column" gap={3}>
      <Typography variant="h3" fontWeight="bold">
        {remindersStateToListOfCounts.length > 0
          ? t("reminders.youHaveReminders")
          : t("reminders.youDontHaveReminders")}
      </Typography>
      <Grid spacing={1.5} container>
        {remindersStateToListOfCounts.map((item, index) => (
          <Grid item xs={6} md={4} key={index}>
            <ReminderCard type={item.type} count={item.count} />
          </Grid>
        ))}
      </Grid>
      {/* Leave it for now as don't know what to do with it */}
      {/* <ReminderCardWrapper mt={2}>
        <Box sx={{ position: "relative", width: 200, height: 140 }}>
          <Box sx={{ position: "absolute", left: 0, bottom: -30, right: 0, textAlign: "center" }}>
            <PhoneCanvas height={180} width={100} />
          </Box>
          <Box display="flex" justifyContent="center" sx={{ position: "absolute", left: 0, right: 0, bottom: 5 }}>
            <ReelsOnboardingAnimation width={80} height={120} />
          </Box>
        </Box>
        <Box display="flex" py={1} width="100%" gap={0.5} px={2} flexDirection="column">
          <Typography variant="h3" fontWeight="bold">
            {t("reminders.empty_title")}
          </Typography>
          <Typography>{t("reminders.empty_subtitle")}</Typography>
          <AppButton fullWidth size="small">
            <Typography fontWeight="bold">{t("reminders.empty_button")}</Typography>
          </AppButton>
        </Box>
      </ReminderCardWrapper> */}

      {isLoading && (
        <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

function RemidersModal() {
  const { t } = useTranslation();

  const {
    currentProfile: { firstName }
  } = useGetCurrentUserProfile();

  const { isOpen, setIsOpen } = useRemindersModalStore();
  const { refetch } = useReminders();
  useRemindersFollowup();
  const {
    featuresConfig: {
      flags: { isRemindersEnabled }
    }
  } = useFeaturesQuery();

  useEffect(() => {
    if (isOpen) refetch();
  }, [isOpen, refetch]);

  if (!isRemindersEnabled) return null;

  return (
    <Modal
      title={t("reminders.hello", { name: firstName })}
      open={isOpen}
      closeModal={() => {
        setIsOpen(false);
      }}
    >
      <RemindersComponent />
    </Modal>
  );
}

interface ReminderCardConfig {
  color: string;
  title: string;
  icon: React.ReactElement;
  actionTitle: string;
  action: () => void;
}

function ReminderCard({ type, count }: RemiderGridItem) {
  const { t } = useTranslation();
  const {
    navigateToCoursesDirectory,
    navigateToPendingKnowledgeRequests,
    navigateToUnwatchedReels,
    navigateToSurvey,
    navigateToImportantPosts,
    navigateToPendingAnswers
  } = useNavigationHelper();
  const { setIsOpen } = useRemindersModalStore();

  const config: ReminderCardConfig = useMemo(() => {
    switch (type) {
      case ReminderType.KnowledgeRequestVerification:
        return {
          title: t("reminders.requests"),
          color: "#3282EC",
          icon: <RemindersVerify />,
          actionTitle: t("reminders.toAnswer"),
          action: () => (count > 0 ? navigateToPendingKnowledgeRequests() : null)
        };
      case ReminderType.KnowledgeRequestsAnswers:
        return {
          title: t("reminders.answers"),
          color: "#3282EC",
          icon: <RemindersAnswers />,
          actionTitle: t("reminders.toVerify"),
          action: () => (count > 0 ? navigateToPendingAnswers() : null)
        };
      case ReminderType.Reels:
        return {
          title: t("reminders.reels"),
          color: theme.colors.primary,
          icon: <RemindersReels />,
          actionTitle: t("reminders.watch"),
          action: () => (count > 0 ? navigateToUnwatchedReels() : null)
        };
      case ReminderType.Courses:
        return {
          title: t("reminders.courses"),
          color: theme.colors.primary,
          icon: <RemindersCoures />,
          actionTitle: t("reminders.do"),
          action: () => (count > 0 ? navigateToCoursesDirectory() : null)
        };
      case ReminderType.Posts:
        return {
          title: count === 1 ? t("reminders.post") : t("reminders.posts"),
          color: theme.colors.pink_2,
          icon: <RemindersPosts />,
          actionTitle: t("reminders.acknowledge"),
          action: () => (count > 0 ? navigateToImportantPosts() : null)
        };
      case ReminderType.Surveys:
        return {
          title: t("reminders.feedback"),
          color: theme.colors.success,
          icon: <RemindersFeedback />,
          actionTitle: t("reminders.outstanding"),
          action: () => (count > 0 ? navigateToSurvey("first") : null)
        };
    }
  }, [
    type,
    t,
    count,
    navigateToPendingKnowledgeRequests,
    navigateToPendingAnswers,
    navigateToUnwatchedReels,
    navigateToCoursesDirectory,
    navigateToImportantPosts,
    navigateToSurvey
  ]);

  return (
    <ReminderCardWrapper
      sx={{ cursor: count > 0 ? "pointer" : "not-allowed" }}
      onClick={() => {
        config.action();
        setIsOpen(false);
      }}
      pt={1}
      display="flex"
      alignItems="center"
    >
      <Box px={1} flexDirection="column" display="flex" width="100%" justifyContent="space-between" gap={1}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <CircleIconWrapper flexShrink={1} color={config.color}>
            {config.icon}
          </CircleIconWrapper>
          <Typography variant="h2" fontWeight="bold">
            {count}
          </Typography>
        </Box>

        <Typography textAlign="left" color={theme.colors.gray_3} fontSize={12}>
          {config.title} {t("to")} {config.actionTitle}
        </Typography>
      </Box>
    </ReminderCardWrapper>
  );
}

export { RemidersModal, RemindersComponent };
