import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const NotificationListWrapper = styled(Box)`
  flex-direction: column;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const ReactionEmoji = styled.span`
  font-size: 12px;
`;
