import { useFeaturesQuery } from "application/features";
import { useReminders } from "application/reminders";
import { RemindersModalState } from "application/reminders/reminders-state";
import { useAuth } from "context/auth-context";
import { useEffect, useMemo } from "react";
import { useSessionStorage } from "usehooks-ts";
import create from "zustand";

export const useRemindersModalStore = create<RemindersModalState>(set => {
  return {
    isOpen: false,
    setIsOpen: (isOpen: boolean) => set(state => ({ ...state, isOpen }))
  };
});

export const useRemindersFollowup = () => {
  const {
    featuresConfig: {
      flags: { isRemindersEnabled }
    }
  } = useFeaturesQuery();

  const { identity } = useAuth();

  const [wasLoadedFromLink] = useSessionStorage("wasLoadedFromLink", false);

  const { data: reminders, isLoading } = useReminders();

  const { setIsOpen } = useRemindersModalStore();

  const areThereAnyReminders = useMemo(() => {
    return (
      (reminders && reminders.courses && reminders.courses.length > 0) ||
      (reminders?.krRequiredAnswer && reminders.krRequiredAnswer.length > 0) ||
      (reminders?.krRequiredVerification && reminders.krRequiredVerification.length > 0) ||
      (reminders?.posts && reminders.posts.length > 0) ||
      (reminders?.surveys && reminders.surveys.length > 0) ||
      (reminders?.reels && reminders.reels.length > 0)
    );
  }, [reminders]);

  useEffect(() => {
    if (!isRemindersEnabled || isLoading) return;
    const reminders = localStorage.getItem("reminders");
    if (areThereAnyReminders && !wasLoadedFromLink && !identity?.isAnonymous) {
      const today = new Date();
      const todayDate = today.getDate();
      const todayMonth = today.getMonth();
      const todayYear = today.getFullYear();
      const remindersDate = new Date(reminders!);
      const remindersDateDate = remindersDate.getDate();
      const remindersDateMonth = remindersDate.getMonth();
      const remindersDateYear = remindersDate.getFullYear();
      if (todayDate === remindersDateDate && todayMonth === remindersDateMonth && todayYear === remindersDateYear) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
        localStorage.setItem("reminders", new Date().toString());
      }
    } else {
      setIsOpen(false);
    }
  }, [setIsOpen, isRemindersEnabled, areThereAnyReminders, isLoading, wasLoadedFromLink, identity?.isAnonymous]);
};
