import React, { FC, useCallback } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { Trans } from "react-i18next";
import { MdClose } from "react-icons/md";

export type ToastContextType = {
  showSnackBar?: (
    text: string,
    untranslatable?: string,
    severity?: "error" | "success" | "info" | "warning" | undefined,
    component?: React.ReactNode,
    keepAliveFor?: number
  ) => void;
};

const ToastContext = React.createContext<ToastContextType>({});
// 👇 This is for loging readybility
ToastContext.displayName = "ToastContext";

interface Options {
  show: boolean;
  severity?: "error" | "success" | "info" | "warning" | undefined;
  text: string;
  untranslatable?: string;
  component?: React.ReactNode;
  keepAliveFor?: number;
}

const ToastProvider: FC = props => {
  const [options, setOptions] = React.useState<Options>({
    show: false,
    severity: undefined,
    text: "",
    untranslatable: ""
  });

  const value = {
    showSnackBar: (
      text: string,
      untranslatable?: string,
      severity?: "error" | "success" | "info" | "warning" | undefined,
      component?: React.ReactNode,
      keepAliveFor?: number
    ) => {
      setOptions({ show: true, severity, text, untranslatable, component, keepAliveFor });
    }
  };

  const onClose = useCallback(
    () => setOptions({ show: false, severity: undefined, text: "", untranslatable: undefined, component: undefined }),
    []
  );

  return (
    <>
      <ToastContext.Provider value={value} {...props} />
      {options.text !== "" && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={options.show}
          key={uuidv4()}
          autoHideDuration={options.keepAliveFor ? options.keepAliveFor : 3000}
          onClose={onClose}
        >
          <Box>
            {!options.component && (
              <Alert
                sx={{ width: "auto" }}
                data-testid="alert"
                severity={options.severity || "success"}
                onClose={onClose}
              >
                <Trans i18nKey={`alerts.${options.text}`}>{options.text}</Trans> {options.untranslatable}
              </Alert>
            )}
            <Box display="flex">
              {options.component} <MdClose size={20} onClick={onClose} />
            </Box>
          </Box>
        </Snackbar>
      )}
    </>
  );
};

function useToast() {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }
  return context;
}

export { ToastProvider, useToast };
