import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  subMonths,
  subYears
} from "date-fns";
import { Duration } from "luxon";
import ReactTimeago from "react-timeago";

/**
 * Formats the date to post-like date representation
 *
 * @param {string} date in ISO 8601 format
 *
 * @returns {string} formatted date
 */
export const toPostDateTimeFormat = (currentDate: number, date: string) => {
  return `${toDateTimeFormat(date)}, ${timeSinceNow(currentDate, date)}`;
};

export const remainingTime = (futureDate?: string) => {
  const result = [];
  const now = new Date();
  var future = futureDate ? new Date(futureDate) : new Date();

  const years = differenceInYears(future, now);
  if (years > 0) {
    result.push(`${years} years`);
    future = subYears(future, years);
  }

  const months = differenceInMonths(future, now);
  if (months > 0) {
    result.push(`${months} months`);
    future = subMonths(future, months);
  }

  const days = differenceInDays(future, now);
  if (days > 0) {
    result.push(`${days} ${days === 1 ? "day" : "days"}`);
  }
  const hours = differenceInHours(future, now);
  if (days <= 0 && months <= 0 && years <= 0) {
    result.push(`${hours} hours`);
  }
  const minutes = differenceInMinutes(future, now);
  if (hours <= 0 && days <= 0 && months <= 0 && years <= 0) {
    result.push(`${minutes} minutes`);
  }

  return result.join(" ");
};

/**
 * Formats the date in {@link https://en.wikipedia.org/wiki/ISO_8601|ISO 8601} format to its local time representation
 *
 * @example
 * const date = '2021-03-23T12:32:41.449Z';
 * toDateTimeFormat(date);
 * // returns '1:32:41 PM';
 *
 * @param {string} date in ISO 8601 format
 *
 * @returns {string} formatted local time format
 */
export const toDateTimeFormat = (date: string) => {
  isDateValid(date);

  return new Intl.DateTimeFormat("en-AU", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short"
  })
    .format(new Date(date))
    .slice(0, -6)
    .toLocaleUpperCase();
};

/**
 * Returns date, sample output '23 Feb 2019'
 *
 * @param {string} date in ISO 8601 format
 *
 * @returns {string} formatted date
 */
export const toLongDateFormat = (date: string) => {
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(new Date(date));

  return `${toDateWithoutYearFormat(date)} ${year}`;
};

/**
 * Returns date without year, sample output '23 Feb'
 *
 * @param {string} date in ISO 8601 format
 *
 * @returns {string} formatted date
 */
export const toDateWithoutYearFormat = (date: string) => {
  const formattedDate = new Date(date);
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(formattedDate);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(formattedDate);

  return `${day} ${month}`;
};

/**
 * Returns date without year, sample output '23 Feb'
 *
 * @param {string} date in ISO 8601 format
 *
 * @returns {string} formatted date
 */
export const toLongDateWithoutYearFormat = (date: string) => {
  const formattedDate = new Date(date);
  const month = new Intl.DateTimeFormat("en", { month: "long" }).format(formattedDate);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(formattedDate);

  return `${month} ${day}`;
};

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const WEEK_IN_MILLISECONDS = 7 * DAY_IN_MILLISECONDS;
const YEAR_IN_MILLISECONDS = 365 * DAY_IN_MILLISECONDS;

/**
 * Returns the relative time from today
 *
 * @param {string} date in ISO 8601 format
 *
 * @returns {string} formatted date
 */
export const timeSinceNow = (currentDate: number, date: string) => {
  isDateValid(date);
  const timeDifference = currentDate - Date.parse(date);

  if (timeDifference < DAY_IN_MILLISECONDS) return "Today";
  if (timeDifference < DAY_IN_MILLISECONDS * 2) return "Yesterday";
  if (timeDifference < WEEK_IN_MILLISECONDS) return `${Math.round(timeDifference / DAY_IN_MILLISECONDS)} days ago`;
  if (timeDifference < YEAR_IN_MILLISECONDS) return toDateWithoutYearFormat(date);

  return toLongDateFormat(date);
};

/**
 * Throws Error if the date has an invalid format
 *
 * @param {string} date in ISO 8601 format
 */
const isDateValid = (date: string) => {
  const dateFormat = new Date(date);

  if (Object.prototype.toString.call(dateFormat) !== "[object Date]" || isNaN(dateFormat.getTime()))
    throw new Error("The provided string has a invalid date format");
};

/**
 * Returns the date in format DD.MM.YYYY
 *
 * @param {string} date in ISO 8601 format
 *
 * @returns {string} formatted date
 */
export const toDateFormat = (date: string) =>
  Intl.DateTimeFormat("en-GB", { month: "2-digit", day: "2-digit", year: "numeric" }).format(new Date(date));

export const getMonthName = function (idx: number) {
  var objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx - 1);

  var locale = "en-GB",
    month = objDate.toLocaleString(locale, { month: "long" });

  return month;
};

export const timeAgoCommentFormatter = (value: number, unit: ReactTimeago.Unit) => {
  // TODO - will translations from react-i18next be enough here?
  if (unit === "second") {
    if (value < 45) {
      return "now";
    }
    if (value < 90) {
      return "1m";
    }
  }
  if (unit === "minute") {
    if (value < 45) {
      return `${value}m`;
    }
    if (value < 90) {
      return `~1h`;
    }
  }
  if (unit === "hour") {
    if (value < 24) {
      return `${value}h`;
    }
    if (value < 48) {
      return `~1d`;
    }
  }
  if (unit === "day") {
    if (value < 30) {
      return `${value}d`;
    }
  }
  if (unit === "month") {
    if (value < 2) {
      return `~1mo`;
    }
    if (value < 12) {
      return `${value}mo`;
    }
  }
  if (unit === "year") {
    if (value < 2) {
      return `~1y`;
    }
    return `${value}y`;
  }
};

export const timeAgoPostNotificationsFormatter = (value: number, unit: ReactTimeago.Unit) => {
  if (unit === "second") {
    if (value < 45) {
      return "now";
    }
    if (value < 90) {
      return "1 minute ago";
    }
  }
  if (unit === "minute") {
    if (value < 45) {
      return `${value} minutes ago`;
    }
    if (value < 90) {
      return `~1 hour ago`;
    }
  }
  if (unit === "hour") {
    if (value < 24) {
      return `${value} hour ago`;
    }
  }
  if (unit === "day") {
    if (value < 2) {
      return `${value} day ago`;
    }
    if (value < 30) {
      return `${value} days ago`;
    }
  }
  if (unit === "week") {
    if (value < 2) {
      return `${value} week ago`;
    }
    if (value < 30) {
      return `${value} weeks ago`;
    }
  }
  if (unit === "month") {
    if (value < 2) {
      return `1 month ago`;
    }
    if (value < 12) {
      return `${value} months ago`;
    }
  }
  if (unit === "year") {
    if (value < 2) {
      return `1 year ago`;
    }
    return `${value} years ago`;
  }
};

export const minutesToRichTimeConverter = (minutes: number) => {
  if (minutes < 1) {
    return `${minutes}m`;
  }
  const duration = Duration.fromObject({ minutes });
  return duration.rescale().toHuman({ unitDisplay: "narrow" }).replace(",", "");
};
