import { useAuth } from "context/auth-context";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface RouteParams {
  tenant: string;
}

const useTenant = () => {
  const { tenant: tenantFromRoute } = useParams<RouteParams>();
  const { identity, isAuthenticated, logout } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const activeTenant = localStorage.getItem("activeTenant");

  const setActiveTenant = (tenantId: string) => {
    localStorage.setItem("activeTenant", tenantId);
  };

  useEffect(() => {
    if (identity && tenantFromRoute) {
      const tenantsOfAuthenticatedUser = Object.keys(identity.tenantInfo);
      if (
        typeof logout === "function" &&
        !tenantsOfAuthenticatedUser.includes(tenantFromRoute) &&
        !identity?.permissions?.includes("tenant:admin-read")
      ) {
        logout();
      }
    }
  }, [activeTenant, logout, identity, tenantFromRoute]);

  useEffect(() => {
    setIsLoading(true);

    if (!isAuthenticated) {
      localStorage.removeItem("activeTenant");
    }

    if (identity && !activeTenant) {
      const tenantsOfAuthenticatedUser = Object.keys(identity.tenantInfo);
      localStorage.setItem("activeTenant", tenantsOfAuthenticatedUser[0]);
    } else {
      setIsLoading(false);
    }
  }, [activeTenant, identity, isAuthenticated]);

  return {
    activeTenant: activeTenant || "",
    setActiveTenant,
    isLoading
  };
};

export { useTenant };
