import { theme } from "@talkouttech/portal-shared";
import { useAuth, useClient } from "context/auth-context";
import { Tenant } from "domain/company";
import { useTenant } from "hooks/useTenant";
import { useQuery } from "react-query";

export const useTenantDetails = () => {
  const { isAuthenticated } = useAuth();
  const client = useClient<Tenant>();
  const { activeTenant } = useTenant();

  const { data, isLoading } = useQuery<Tenant, Error>({
    enabled: isAuthenticated,
    queryKey: ["tenant-details", activeTenant],
    queryFn: () => client(`tenants/${activeTenant}`)
  });

  return { data, isLoading };
};

export function useGetTenantBranding() {
  const tenantDetails = useTenantDetails();

  const headerBackgroundColour = tenantDetails?.data?.brandConfig?.headerBackgroundColour ?? theme.colors.black;
  const headerForegroundColour = tenantDetails?.data?.brandConfig?.headerForegroundColour ?? "#fff";
  const logoUrl = tenantDetails?.data?.brandConfig?.logoUrl;

  return {
    headerBackgroundColour,
    headerForegroundColour,
    logoUrl
  };
}
