import { IconButton, styled } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";
import { css } from "@emotion/react";

const buttonStyles = css`
  background-color: ${theme.colors.border};
  width: 40px;
  height: 40px;
  position: absolute;

  &:hover {
    background-color: ${theme.colors.background.paper};
  }
`;

export const CloseIconWrapper = styled(IconButton)`
  right: 24px;
  ${buttonStyles};
`;

export const BackIconWrapper = styled(IconButton)`
  left: 24px;
  ${buttonStyles};
`;
