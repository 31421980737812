import { useFeaturesQuery } from "application/features";
import { useGetCurrentUserProfile } from "application/users";
import { useAuth, useClient } from "context/auth-context";
import { Reminders } from "domain/reminders";
import { useTenant } from "hooks/useTenant";
import { useQuery } from "react-query";

export const useReminders = () => {
  const client = useClient<Reminders>();
  const {
    featuresConfig: {
      flags: { isRemindersEnabled }
    }
  } = useFeaturesQuery();
  const {
    currentProfile: { id: userId }
  } = useGetCurrentUserProfile();
  const { activeTenant } = useTenant();
  const { identity } = useAuth();

  const { data, isLoading, refetch } = useQuery<Reminders, Error>({
    queryKey: [`reminders`, activeTenant, userId],
    queryFn: () =>
      client(`tenants/${activeTenant}/profiles/${userId}/reminders`)
        .then(data => data)
        .catch(err => err),
    enabled: !!userId && !identity?.isAnonymous && isRemindersEnabled
  });

  return { data: data, isLoading, refetch };
};
